import {
    ThemeProvider,
    createTheme,
    Box,
    Container,
    Paper,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CssBaseline from '@mui/material/CssBaseline';
import { Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import NavBar from './common/components/navbar/NavBar';
import Artefacts from './features/artefacts/Artefacts';
import Artefact from './features/artefacts/Artefact';
import Faqs from './features/faqs/Faqs';
import Games from './features/games/Games';
import Game from './features/games/Game';
import GamesDiary from './features/games/GameDiary';
import EditGame from './features/games/EditGame';
import Gods from './features/gods/Gods';
import God from './features/gods/God';
import Heroes from './features/heroes/Heroes';
import Hero from './features/heroes/Hero';
import Monsters from './features/monsters/Monsters';
import Monster from './features/monsters/Monster';
import Summons from './features/summons/Summons';
import Summon from './features/summons/Summon';
import CommonInnateAbilities from './features/commonInnateAbilities/CommonInnateAbilities';
import Home from './features/home/Home';
import StarterMaps from './features/pages/starterMaps';
import GameDefinitions from './features/gameDefinitions/GameDefinitions';
import PlayerRankings from './features/playerRankings/PlayerRankings';
import PlayerRankings3v3 from './features/playerRankings/PlayerRankings3v3';
import TournamentResults from './features/tournamentResults/TournamentResult';
import Tournaments from './features/tournamentResults/Tournaments';
import PlayerResults from './features/tournamentResults/PlayerResults';
import Page from './features/pages/page';
import CommonInnateAbility from './features/commonInnateAbilities/CommonInnateAbility';

ReactGA.initialize('G-JR2BNYJ4VG');
ReactGA.pageview(window.location.pathname + window.location.search);

const theme = createTheme({
    palette: {
        primary: {
            main: '#f1e2be', // tan
        },
        secondary: {
            main: '#6b351c', // brown
        },
    },
    typography: {
        fontFamily: 'Roboto Normal',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#8b5e3c',
                },
            },
        },
    },
});

theme.typography.h1 = {
    color: '#6b351c',
    fontFamily: 'Roboto Condensed Bold',
    fontSize: '24px',
};

theme.typography.h2 = {
    color: '#6b351c',
    fontFamily: 'Roboto Condensed Bold',
    fontSize: '20px',
};

theme.typography.h3 = {
    color: '#6b351c',
    fontFamily: 'Roboto Condensed Bold',
    fontSize: '16px',
};

function App(): JSX.Element {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Box>
                    <NavBar />
                    <Container maxWidth="xl">
                        <Paper elevation={3} sx={{ mt: 1, p: 1 }}>
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route
                                    path="/artefacts"
                                    element={<Artefacts />}
                                />
                                <Route
                                    path="/artefacts/:artefactUrlName"
                                    element={<Artefact />}
                                />
                                <Route path="/gods" element={<Gods />} />
                                <Route
                                    path="/gods/:godUrlName"
                                    element={<God />}
                                />
                                <Route path="/heroes" element={<Heroes />} />
                                <Route
                                    path="/heroes/:heroUrlName"
                                    element={<Hero />}
                                />
                                <Route
                                    path="/monsters"
                                    element={<Monsters />}
                                />
                                <Route
                                    path="/monsters/:monsterUrlName"
                                    element={<Monster />}
                                />
                                <Route path="/summons" element={<Summons />} />
                                <Route
                                    path="/summons/:summonUrlName"
                                    element={<Summon />}
                                />
                                <Route path="/faqs" element={<Faqs />} />
                                <Route path="/games" element={<Games />} />
                                <Route
                                    path="/games/diary"
                                    element={<GamesDiary />}
                                />
                                <Route
                                    path="/games/:gameId"
                                    element={<Game />}
                                />
                                <Route
                                    path="/games/editgame"
                                    element={<EditGame />}
                                />
                                <Route
                                    path="/games/editgame/:gameId"
                                    element={<EditGame />}
                                />
                                <Route
                                    path="/commoninnateabilities"
                                    element={<CommonInnateAbilities />}
                                />
                                <Route
                                    path="/commoninnateabilities/id/:id"
                                    element={<CommonInnateAbility />}
                                />
                                <Route
                                    path="/commoninnateabilities/:name"
                                    element={<CommonInnateAbility />}
                                />
                                <Route
                                    path="/startermaps"
                                    element={<StarterMaps />}
                                />
                                <Route
                                    path="/playerrankings/3v3"
                                    element={<PlayerRankings3v3 />}
                                />
                                <Route
                                    path="/playerrankings/5v5"
                                    element={<PlayerRankings />}
                                />
                                <Route
                                    path="/tournamentresults/player/:player"
                                    element={<PlayerResults />}
                                />
                                <Route
                                    path="/tournamentresults/tournament/:tournament"
                                    element={<TournamentResults />}
                                />
                                <Route
                                    path="/tournamentresults/tournaments"
                                    element={<Tournaments />}
                                />
                                <Route
                                    path="/gamedefinitions"
                                    element={<GameDefinitions />}
                                />
                                <Route
                                    path="/pages/:pageName"
                                    element={<Page />}
                                />
                            </Routes>
                        </Paper>
                    </Container>
                </Box>
            </ThemeProvider>
        </LocalizationProvider>
    );
}

export default App;
