import {
    Skeleton,
    Box,
    Checkbox,
    SnackbarCloseReason,
    Snackbar,
    FormControl,
    FormControlLabel,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import '../../main.css';
import { useState } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useGetGameByIdQuery, useUpdateGameMutation } from './gamesApi';
import GameHeroCard from './GameHeroCard';
import GameType from './interfaces/Game';

function Game() {
    const { user } = useUser();
    const loggedInUser = user?.username || '';
    const { gameId } = useParams() as { gameId: string };
    const { data: game, isLoading, refetch } = useGetGameByIdQuery(gameId);

    const [updateGame, { isLoading: isUpdating }] = useUpdateGameMutation();

    // ===============
    // checkbox state
    // ===============
    const [thisIsMeWinner, setThisIsMeWinner] = useState(false);
    const [thisIsMeLoser, setThisIsMeLoser] = useState(false);

    // ===============
    // snackbar state
    // ===============
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');

    const handleSnackbarClose = (
        event: Event | React.SyntheticEvent<any, Event>,
        reason: SnackbarCloseReason
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    if (!game) {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid xs={12}>Game not Found</Grid>
                </Grid>
            </Box>
        );
    }

    // ===============
    // handle update
    // ===============
    const handleUpdate = async (
        event: React.FormEvent,
        updatedGame: GameType
    ) => {
        event.preventDefault();
        try {
            await updateGame(updatedGame).unwrap();
            refetch();
            setSnackBarMessage('Game updated successfully');
            setSnackbarOpen(true);
        } catch (error) {
            if ((error as { status: number }).status === 422) {
                const errorObject = error as { status: number; data: any };
                setSnackBarMessage(
                    `Game not updated: ${errorObject.data.reason}`
                );
            } else if (error instanceof Error) {
                setSnackBarMessage(`Game not updated: ${error.message}`);
            } else {
                setSnackBarMessage(
                    'Game not updated: An unknown error occurred'
                );
            }
        }
        setSnackbarOpen(true);
    };

    if (isUpdating) {
        return <Skeleton variant="rectangular" height={60} />;
    }

    const handleThisIsMeWinnerChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        event.preventDefault();
        let validationError: boolean = false;
        // ensure user is not already marked as loser
        if (game?.loser.user_name === loggedInUser) {
            validationError = true;
            setThisIsMeWinner(false);
            setSnackBarMessage(
                'You are already recorded as the loser of this game. You cannot be the winner and the loser of the same game!'
            );
            setSnackbarOpen(true);
            return;
        }
        if (!validationError) {
            if (!validationError) {
                const updatedGame = {
                    ...game,
                    winner: {
                        ...game.winner,
                        user_name: loggedInUser,
                    },
                };
                handleUpdate(event, updatedGame);
            }
        }
    };

    const handleThisIsMeLoserChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        event.preventDefault();
        let validationError: boolean = false;
        // ensure user is not already marked as winner
        if (game.winner.user_name === loggedInUser) {
            validationError = true;
            setThisIsMeLoser(false);
            setSnackBarMessage(
                'You are already recorded as the winner of this game. You cannot be the winner and the loser of the same game!'
            );
            setSnackbarOpen(true);
            return;
        }
        if (!validationError) {
            const updatedGame = {
                ...game,
                loser: {
                    ...game.loser,
                    user_name: loggedInUser,
                },
            };
            handleUpdate(event, updatedGame);
        }
    };

    const formattedDate = dayjs(game.game_date).format('DD MMM YYYY');

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid xs={12} sm={4} md={3} lg={2}>
                    <div>
                        <b>Date:</b>&nbsp;{formattedDate}
                    </div>
                </Grid>
                <Grid xs={12} sm={4} md={3} lg={2}>
                    <div>
                        <b>Size:</b>&nbsp;{game.size}
                    </div>
                </Grid>
                <Grid xs={12} sm={4} md={3} lg={2}>
                    <div>
                        <b>Format:</b>&nbsp;{game.format}
                    </div>
                </Grid>
                <Grid xs={12} sm={4} md={3} lg={2}>
                    <div>
                        <b>Patch:</b>&nbsp;{game.patch}
                    </div>
                </Grid>
                <Grid xs={12}>
                    {' '}
                    <h2>Winning Warband: {game.winner.god}</h2>
                    <h3>
                        {game.winner.user_name.length > 0
                            ? `Player: ${game.winner.user_name}`
                            : 'Player: Unknown'}
                    </h3>
                    {game.winner.user_name.length === 0 &&
                        loggedInUser.length > 0 && (
                            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={
                                                handleThisIsMeWinnerChange
                                            }
                                            name="thisIsMeWinner"
                                            color="primary"
                                            checked={thisIsMeWinner}
                                        />
                                    }
                                    label="I was the winning player of this game"
                                />
                            </FormControl>
                        )}
                    <img
                        src={`/images/${game.winner.god.toLowerCase()}-logo.jpg`}
                        alt={game.winner.god}
                        style={{
                            width: '100px',
                            height: '100px',
                            verticalAlign: 'top',
                        }}
                    />
                </Grid>
                {game.winner.heroes.map((hero) => {
                    return (
                        <Grid key={hero.url_name} xs={12} sm={4} md={3} lg={2}>
                            <GameHeroCard gameHero={hero} />
                        </Grid>
                    );
                })}
                <Grid xs={12}>
                    {' '}
                    <h2>Losing Warband: {game.loser.god}</h2>
                    <h3>
                        {game.loser.user_name.length > 0
                            ? `Player: ${game.loser.user_name}`
                            : 'Player: Unknown'}
                    </h3>
                    {game.loser.user_name.length === 0 &&
                        loggedInUser.length > 0 && (
                            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={handleThisIsMeLoserChange}
                                            name="thisIsMeLoser"
                                            color="primary"
                                            checked={thisIsMeLoser}
                                        />
                                    }
                                    label="I was the losing player of this game"
                                />
                            </FormControl>
                        )}
                    <img
                        src={`/images/${game.loser.god.toLowerCase()}-logo.jpg`}
                        alt={game.loser.god}
                        style={{
                            width: '100px',
                            height: '100px',
                            verticalAlign: 'top',
                        }}
                    />
                </Grid>
                {game.loser.heroes.map((hero) => {
                    return (
                        <Grid key={hero.url_name} xs={12} sm={4} md={3} lg={2}>
                            <GameHeroCard gameHero={hero} />
                        </Grid>
                    );
                })}
            </Grid>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                    >
                        <MuiAlert
                            onClose={(event) =>
                                handleSnackbarClose(event, 'timeout')
                            }
                            severity="warning"
                            sx={{ width: '100%' }}
                        >
                            {snackBarMessage}
                        </MuiAlert>
                    </Snackbar>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Game;
